<template>
  <div class="wa--users-search">
    <h1 class="wind--title">
      检索用户
    </h1>
    <cv-definition-tooltip
      alignment="start"
      direction="bottom"
      definition="真实姓名，电话，邮箱，工作单位。"
      term="可搜索范围包括...">
    </cv-definition-tooltip>
    <cv-search
      placeholder="请输入关键词..."
      clear-aria-label="清空"
      v-model="keyword">
    </cv-search>
    <div class="bx--form-item">
      <cv-accordion>
        <cv-accordion-item>
          <template v-slot:title>
            更多检索选项
          </template>
          <template v-slot:content>
            <cv-dropdown
              inline
              label="用户角色"
              v-model="searchFilters.role">
              <cv-dropdown-item
                v-for="role in userRolesFilters"
                :key="role.label"
                :value="role.value">
                {{ role.label }}
              </cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
              inline
              label="用户审核"
              v-model="searchFilters.status">
              <cv-dropdown-item
                v-for="status in userStatusFilters"
                :key="status.label"
                :value="status.value">
                {{ status.label }}
              </cv-dropdown-item>
            </cv-dropdown>
          </template>
        </cv-accordion-item>
      </cv-accordion>
    </div>
    <div class="bx--form-item">
      <cv-button
        kind="primary"
        @click="search">
        检索
      </cv-button>
    </div>
    <h3 class="wind--title" v-if="users.length == 0 && !loading">
      未找到用户
    </h3>
    <cv-data-table-skeleton v-if="loading"></cv-data-table-skeleton>
    <cv-data-table
      v-if="users.length > 0 && !loading"
      :columns="columns"
      :has-expanded-all="false">
      <template v-slot:data>
        <cv-data-table-row
          v-for="(user, rowIndex) in displayedUsers"
          :key="user._id"
          :expanded="rowIndex === rowExpanded">
          <cv-data-table-cell>
            {{ user.real_name }}
          </cv-data-table-cell>
          <cv-data-table-cell>
            {{ user.workplace }}
          </cv-data-table-cell>
          <cv-data-table-cell>
            {{ user.phone }}
          </cv-data-table-cell>
          <cv-data-table-cell>
            {{ user.email }}
          </cv-data-table-cell>
          <cv-data-table-cell>
            <cv-tag
              :label="selectionsValueToLabel(user.role, userRoles)"
              :kind="selectionsValueToLabel(user.role, userRolesColors)">
            </cv-tag>
          </cv-data-table-cell>
          <cv-data-table-cell>
            <cv-tag
              :label="user.status === 'admitted' ? '通过' : '未通过'"
              :kind="user.status === 'admitted' ? 'green' : 'gray'">
            </cv-tag>
          </cv-data-table-cell>
          <cv-data-table-cell>
            <cv-overflow-menu flip-menu>
              <cv-overflow-menu-item @click="openQuickActions(user)">
                关键信息
              </cv-overflow-menu-item>
              <cv-overflow-menu-item
                disabled>
                绑定风场
              </cv-overflow-menu-item>
              <cv-overflow-menu-item
                disabled>
                详细信息
              </cv-overflow-menu-item>
              <cv-overflow-menu-item
                danger
                @click="deleteUser(user)">
                删除用户
              </cv-overflow-menu-item>
            </cv-overflow-menu>
          </cv-data-table-cell>
          <template slot="expandedContent">
            <span>
              用户ID：{{ user._id }}
            </span>
            <span class="wind--text-divider"></span>
            <span>
              身份信息：{{ user.identification }}
            </span>
          </template>
        </cv-data-table-row>
      </template>
    </cv-data-table>
    <cv-pagination
      backward-text="上一页"
      forward-text="下一页"
      :forwards-button-disabled="users.length == 0 || this.noMore"
      page-number-label="页数："
      page-sizes-label="显示项数目："
      :page-sizes="[10, 20, 50]"
      :page="page"
      @change="pageChange">
      <template v-slot:range-text>
        {{ skip + 1 }}-{{ skip + displayedUsers.length }}
      </template>
      <template v-slot:of-n-pages>
        第 {{ page }} 页
      </template>
    </cv-pagination>
    <wa-scope
      v-slot="{ dataCopy }"
      ref="quickActionsScope">
      <validation-observer v-slot="{ handleSubmit, reset, invalid, pristine }">
        <cv-modal
          :visible="quickActionsVisible"
          @modal-hidden="quickActionsVisible = false; reset()"
          @primary-click="handleSubmit(updateUser(dataCopy))"
          :primary-button-disabled="invalid || pristine"
          has-form-content>
          <template v-slot:label>
            关键数据
          </template>
          <template v-slot:title>
            {{ dataCopy.real_name }}
          </template>
          <template v-slot:content>
            <validation-provider>
              <cv-dropdown
                label="用户角色"
                v-model="dataCopy.role">
                <cv-dropdown-item
                  v-for="role in userRoles"
                  :key="role.label"
                  :value="role.value">
                  {{ role.label }}
                </cv-dropdown-item>
              </cv-dropdown>
            </validation-provider>
            <validation-provider>
              <cv-dropdown
                label="用户审核"
                v-model="dataCopy.status">
                <cv-dropdown-item
                  v-for="status in userStatus"
                  :key="status.label"
                  :value="status.value">
                  {{ status.label }}
                </cv-dropdown-item>
              </cv-dropdown>
            </validation-provider>
          </template>
          <template v-slot:secondary-button>
            取消
          </template>
          <template v-slot:primary-button>
            更新
          </template>
        </cv-modal>
      </validation-observer>
    </wa-scope>
  </div>
</template>
<script>
import { selections } from '../mixins'
import { WaScope } from '../components'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  CvDefinitionTooltip,
  CvSearch,
  CvAccordion,
  CvAccordionItem,
  CvDropdown,
  CvDropdownItem,
  CvButton,
  CvDataTableSkeleton,
  CvDataTable,
  CvDataTableRow,
  CvDataTableCell,
  CvTag,
  CvOverflowMenu,
  CvOverflowMenuItem,
  CvPagination,
  CvModal
} from '@carbon/vue/src/index'
export default {
  name: 'UsersSearch',
  mixins: [
    selections
  ],
  components: {
    WaScope,
    ValidationObserver,
    ValidationProvider,
    CvDefinitionTooltip,
    CvSearch,
    CvAccordion,
    CvAccordionItem,
    CvDropdown,
    CvDropdownItem,
    CvButton,
    CvDataTableSkeleton,
    CvDataTable,
    CvDataTableRow,
    CvDataTableCell,
    CvTag,
    CvOverflowMenu,
    CvOverflowMenuItem,
    CvPagination,
    CvModal
  },
  data: () => ({
    keyword: '',
    limit: 10,
    skip: 0,
    page: 1,
    loading: true,
    users: [],
    columns: [
      '姓名',
      '工作单位',
      '电话',
      '邮箱',
      '角色',
      '审核',
      '操作'
    ],
    rowExpanded: -1,
    searchFilters: {
      role: '',
      status: ''
    },
    quickActionsVisible: false
  }),
  computed: {
    displayedUsers (){
      if (this.users.length === this.limit + 1) {
        return this.users.slice(0, this.users.length - 1)
      }

      return this.users
    },
    noMore (){
      return this.users.length < this.limit + 1
    },
    searchParams (){
      let params = {
        keyword: this.keyword,
        limit: this.limit + 1,
        skip: this.skip
      }

      if (this.searchFilters.role !== '') {
        params.role = this.searchFilters.role
      }

      if (this.searchFilters.status !== '') {
        params.status = this.searchFilters.status
      }

      return params
    },
    userRolesFilters (){
      let userRolesNone = [{ value: '', label: '无限制' }]
      return userRolesNone.concat(this.userRoles)
    },
    userStatusFilters (){
      let userStatusNone = [{ value: '', label: '无限制' }]
      return userStatusNone.concat(this.userStatus)
    }
  },
  created (){
    this.fetch()
  },
  methods: {
    fetch (){
      this.loading = true
      this.$api.user.find(this.searchParams).then( r => {
        if (r.ok) {
          this.users = r.data.users
        } else {
          this.users = []
        }
        this.loading = false
      })
    },
    search (){
      this.skip = 0
      this.page = 1
      this.fetch()
    },
    pageChange (e){
      this.skip = e.start - 1
      this.page = e.page
      this.limit = e.length
      this.fetch()
    },
    openQuickActions (user){
      this.$refs.quickActionsScope.set(user)
      this.quickActionsVisible = true
    },
    updateUser (user){
      let uid = user._id
      delete user._id
      this.$api.user.update(uid, user).then( r => {
        if (r.ok) {
          this.$store.dispatch('ui/toast-notification/show', {
            title: '关键信息修改成功',
            kind: 'success'
          })
          this.quickActionsVisible = false
          this.fetch()
        } else {
          this.$store.dispatch('ui/toast-notification/show', {
            title: '关键信息修改出错',
            kind: 'warning',
            subTitle: r.error.status,
            caption: r.error.description
          })
        }
      })
    },
    deleteUser (user){
      this.$store.dispatch('ui/modal/open', {
        title: `确定删除用户 ${user.real_name} ？`,
        message: '用户被删除后，用户和风场的绑定也会被删除。',
        kind: 'danger',
        confirmText: '删除',
        cancelText: '取消',
        onConfirm: () => {
          this.$api.user.delete(user._id).then( r => {
            if (r.ok) {
              this.$store.dispatch('ui/toast-notification/show', {
                title: `用户 ${user.real_name} 删除成功`,
                kind: 'success'
              })
              this.fetch()
            } else {
              this.$store.dispatch('ui/toast-notification/show', {
                title: `用户 ${user.real_name} 删除出错`,
                kind: 'warning',
                subTitle: r.error.status,
                caption: r.error.description
              })
            }
          })
        }
      })
    }
  }
}
</script>